import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Dropdown,
  Image,
  Modal,
  Select,
  message,
} from "antd";
import { TableGrid } from "../../../Component/Table";
import "./style.scss";
import Api from "../../../Network/ApiConfig";
import Input from "antd/lib/input/Input";
import { CSVLink } from "react-csv";
import { StyleToPRint } from "../../../Component/jsonsStyles/styleJson";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  DownOutlined,
  EditOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import en from "../../../localization/en";
import ar from "../../../localization/ar";
import { useSelector } from "react-redux";
const { Option } = Select;

export const Offers = () => {
  const { confirm } = Modal;
  const history = useHistory();
  const { currentLang } = useSelector((state) => state?.user);
  const t = currentLang === "en" ? en : ar;

  const pdfRef = React.createRef();
  const [leadsList, setLeadsList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [defaultCurrentVal, setDefaultCurrentVal] = useState(10);
  const [TotalCount, setTotalCount] = useState(30);
  const [page, setpage] = useState(0);

  const handelDltRowData = (Id) => {
    confirm({
      title: `${t.DoyouWanttodeletethis} السعر?`,
      // title: localization.DodeletetheseAddress[this.props.currentLang],
      icon: <ExclamationCircleOutlined />,
      okText: t.Ok,
      cancelText: t.Cancel,
      onOk: () => {
        Api.get(`CompanyPackgeSizes/delete?CompanyPackgeSizeId=${Id}`).then(
          (res) => {
            if (res?.status === 200) {
              message.success(res?.data?.metas?.message);
              fetchLeedsList();
            }
            setLoading(false);
          },
          (err) => {
            setLoading(false);
          }
        );
      },
      onCancel() {},
    });
  };

  const fetchLeedsList = (index = 0) => {
    setpage(index);
    setLoading(true);
    Api.get(`AdminAPIs/GetOffers?Page=${index}`).then(
      (res) => {
        if (res?.status === 200) {
          const dataSource = res?.data?.model.map((item, ind) => ({
            ...item,
            key: ind + 1 + index * 30, // Or any other unique identifier
          }));

          setLeadsList(dataSource);
          // setLeadsListFiltered(res?.data?.model);
        }
        if (res?.data?.metas?.TotalaCount) {
          setTotalCount(res?.data?.metas?.TotalaCount);
        }
        setLoading(false);
      },
      (err) => {
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    fetchLeedsList();
  }, [currentLang]);
  const columns = [
    {
      title: <p className="table-header-text"> # </p>,
      dataIndex: "key",
    },
    {
      title: <p className="table-header-text"> {t.deliveryCompany}</p>,
      render: (_, record) => (
        <span>
          {currentLang === "en"
            ? record?.Company?.Name
            : record?.Company?.NameLT}
        </span>
      ),
    },
    // {
    //   title: <p className='table-header-text'> {t.description}</p>,
    //   dataIndex: currentLang === "en" ? 'Description' : 'DescriptionLT',
    // },
    {
      title: <p className="table-header-text"> {t.from}</p>,
      render: (_, record) => (
        <div>
          {currentLang === "en"
            ? record?.FromCity?.Name
            : record?.FromCity?.NameLT}
        </div>
      ),
    },
    {
      title: <p className="table-header-text">{t.to}</p>,
      render: (_, record) => (
        <div>
          {currentLang === "en" ? record?.ToCity?.Name : record?.ToCity?.NameLT}
        </div>
      ),
    },
    {
      title: <p className="table-header-text"> {t.packageSize}</p>,
      render: (_, record) => (
        <div>
          {currentLang === "en"
            ? record?.PackgeSize?.Name
            : record?.PackgeSize?.NameLT}
        </div>
      ),
    },
    {
      title: <p className="table-header-text">{t.ServiceType}</p>,
      render: (_, record) => (
        <div>
          {" "}
          {currentLang === "en"
            ? record?.Service?.Name
            : record?.Service?.NameLT}
        </div>
      ),
    },
    {
      title: <p className="table-header-text">{t.deliveryType}</p>,
      render: (_, record) => {
        if (record?.DeliveryTypeId == 1) {
          return t.Standard;
        } else if (record?.DeliveryTypeId == 2) {
          return t.Express;
        } else if (record?.DeliveryTypeId == 3) {
          return t.Scheduled;
        }
      },
    },
    {
      title: <p className="table-header-text">{t.categoryType}</p>,
      render: (_, record) => {
        if (record?.CategoryId == 2) {
          return t.Standard;
        } else if (record?.CategoryId == 3) {
          return t.Fragile;
        } else if (record?.CategoryId == 4) {
          return t.Reefer;
        }
      },
    },
    // {
    //   title: <p className='table-header-text'> Delivery Type<span> نوع التوصيل </span></p>,
    //   render: (_, record) => <div>{record?.PackgeSize?.NameLT}</div>,
    // },
    {
      title: <p className="table-header-text"> {t.Price}</p>,
      render: (_, record) => <div>{record?.Price}</div>,
    },

    {
      title: <p className="table-header-text"> {t.Actions}</p>,
      dataIndex: "",
      render: (_, record) => {
        return (
          <div className="select-item-action">
            {/* <EditOutlined onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              // handelEditRowData(record?.Id);
            }} style={{ marginInlineEnd: 20, fontSize: 20, cursor: "pointer" }} /> */}
            <DeleteOutlined
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                handelDltRowData(record?.Id);
              }}
              style={{
                marginInlineEnd: 20,
                color: "red",
                fontSize: 20,
                cursor: "pointer",
              }}
            />
          </div>
        );
      },
    },
  ];
  return (
    <div>
      <TableGrid
        TableRef={pdfRef}
        title={
          <div className="table__filter">
            <p style={{ fontWeight: "bold" }}> {t.offers}</p>

            {/* <div className='product__btn'>
            <Button onClick={() => history.push('/offers/add')} className='primary'>New Offer  عرض جديد</Button>
          </div> */}
            {/* <div className='download__list'>
            <CSVLink filename="leads.csv" data={excelData}>{t.Excel}</CSVLink>
            <a onClick={handelPrint}>Pdf</a>
            <Dropdown trigger={'click'} overlay={columnList}>
              <a style={{ width: '135px' }}>
                column visibility <DownOutlined style={{ marginLeft: '7px' }} />
              </a>
            </Dropdown>
          </div>
          <div className='search__list--input'>
            <Input placeholder='Search For Name' onChange={(e) => handleFilterList(e.target.value)} />
          </div> */}
          </div>
        }
        columns={columns || []}
        dataSource={leadsList || []}
        // pagination={{ pageSize: 10 }}
        // defaultCurrent={10}
        loading={loading}
        total={TotalCount}
        onChange={(pagination) => {
          console.log(pagination);

          fetchLeedsList(pagination?.current - 1);
        }}
        pageSize={30}
      />
    </div>
  );
};
