// eslint-disable-next-line import/no-anonymous-default-export
export default {
  home: "الرئيسية",
  aboutUs: "عن شوفير",
  send: "إرسال",
  NewPassword: "كلمة مرور جديدة",
  Message: "الرسالة",
  ComplaintSuggestions: "الشكاوى والمقترحات",
  Createaccount: "إنشاء حساب",
  OrSignup: "قم بالتسجيل معنا",
  Signup: "اشترك الأن",
  Orlogin: "أو تسجيل الدخول",
  Login: "تسجيل دخول",
  OrderDate: "تاريخ الطلب",
  OrdersCount: "عدد الطلبات",
  Editprofile: "تعديل الملف الشخصي",
  Signin: "تسجيل دخول",
  Logout: "تسجيل خروج",
  EmailAddress: "البريد الإلكتروني",
  Password: "كلمه المرور",
  Account: "الحساب",
  contacttype: "نوع الاتصال",
  deliverycompany: "شركه الشحن ",
  Password8characters: " يجب أن تكون كلمة المرور أكبر من 8 رموز",
  Password20characters: " يجب أن تكون كلمة المرور أقل من 20 رمزًا",
  TelephoneNumber: "رقم هاتف",
  Phonenumber: "رقم الهاتف",
  submit: "إرسال",
  cancelationdate: "تاريخ الإلغاء ",
  CompanyName: "اسم الشركة ",
  SendVerificationCode: "أرسل رمز التحقق",
  Emailvalid: "عنوان البريد الإلكتروني غير صحيح",
  twopasswords: "كلمتا المرور التي أدخلتها غير متطابقتين!",
  pleaseselect: "يرجى الاختيار",
  Pleaseinput: "الرجاء الإدخال",
  ReenterPassword: "اعادة ادخال كلمة المرور",
  changepassword: "تغيير كلمة المرور",
  currentpassword: "كلمة المرور الحالية",
  newpassword: "كلمة المرور الجديدة",
  Thetwopasswords: "كلمتا المرور التي أدخلتها غير متطابقتين!",
  confirmpassword: "تأكيد كلمة المرور",
  Confirm: "تأكيد ",
  Forgetpassword: "نسيت كلمة المرور ",
  FullName: "الاسم بالكامل ",
  Verifynow: "التحقق الآن",
  contactUs: "تواصل معنا",
  EditProfile: "تعديل الملف الشخصي",
  MyOrders: "طلباتي",
  MyAdresses: "عناويني",
  MyWallet: "محفظتي",
  Language: "اللغة",
  Delivery: "التوصيل",
  PersonalShopper: "المتسوق الشخصي",
  services: "خدمات",
  footerAboutText: "شوفير يقربلك المسافات",
  footerAddress:
    "الطابق الخامس من مبنى (قرية الأعمال)B ديرة،   مكتب  رقم   S84",
  WeAreOnSocialMedia: "نحن على مواقع التواصل الاجتماعي",
  Shipping: "الشحن",
  RentCar: "التأجير",
  ShareRequirments: "شارك متطلباتك",
  TermsAndCondition: "الشروط والأحكام",
  FAQ: "الأسئلة الشائعة ",
  NewTerm: "شرط جديد ",
  NewFAQ: "الأسئلة الشائعة الجديدة",
  Addfaq: "اضافة سؤال ",
  Updatefaq: "تعديل سؤال ",
  UpdateCategory: "تعديل فئه ",
  ComplaintAndSuggestions: "الشكاوى والمقترحات",
  Help: "مساعدة",
  PrivacyPolicy: "سياسة الخصوصية",
  MyProfile: "ملفي",
  CopyrightsReservedFor: "حقوق النشر محفوظة لـ",
  chauffeurHub: "شوفير هاب",
  curentYear: "2024",
  Company: "الشركة",
  Profile: "الملف الشخصي",
  search: "البحث",
  descriptionAr: "(ar) وصف ",
  descriptionEn: "(en) وصف ",
  drivernameAr: " (ar) اسم السائق ",
  drivernameEn: " (en) اسم السائق",
  nameAr: " (ar) اسم ",
  nameEn: " (en) اسم",
  titleAr: " (ar) العنوان ",
  titleEn: " (en) العنوان",
  altAr: " (ar) الاسم البديل ",
  altEn: " (en) الاسم البديل",
  companynameAr: "(ar) اسم الشركه",
  companynameEn: "(en) اسم الشركه",
  startDate: "تاريخ البدء",
  Save: "الحفظ",
  mainPhoto: "الصورة الرئيسية",
  country: "الدولة ",
  Category: "الفئه ",
  Deliveryfromthecity: "توصيل من المدينة",
  Deliverytothecity: "توصيل إلى المدينة",
  endDate: "تاريخ الانتهاء",
  DoyouWanttodeletethis: " هل ترغب في إلغاء هذا",
  Dashboard: "لوحة التحكم",
  Notifications: "إشعارات",
  Orders: "الطلبات",
  Chauffeurs: "السائقون",
  offers: "العروض",
  PackgeSize: "أحجام الطرد",
  driverpicture: "صورة السائق",
  VehicleType: "نوع المركبة",
  Companyinfo: "بيانات الشركة",
  NationalId: "رقم الهوية الإماراتية",
  VehicleLicence: "رخصة المركبة",
  NewOffer: "عرض جديد ",
  DrivingLicence: "رقم رخصة القيادة",
  NewChauffeur: "سائق جديد",
  UpdateChauffeur: "تحديث بيانات السائق ",
  AssignDriver: "تعيين سائق",
  Assign: "تعيين ",
  ViewDetails: "عرض التفاصيل ",
  CancelOrder: " إلغاء الطلب ",
  Cancel: "  إلغاء  ",
  AddPrice: "إضافة سعر",
  UpdatePrice: "تحديث السعر",
  Price: "السعر",
  Ok: "نعم",
  Print: "طباعة",
  Excel: "اكسل",
  cities: "المدن",
  countries: "الدول",
  FAQCategories: "فئات الأسئلة الشائعة",
  Newcity: "مدينه جديد ",
  city: "مدينة ",
  UpdateCity: "تعديل مدينة ",
  UpdateCompany: "تعديل الشركة ",
  NewCompany: "شركه جديدة ",
  Notassignedtodriver: "لم يتم تعيين سائق",
  Companies: "الشركات",
  NewBlog: "مدونة جديدة",
  Blogs: "المدونات",
  Blog: "مدونة",
  TermsAndConditions: "الشروط والأحكام",
  Reports: "التقارير",
  Settings: "الإعدادات",
  Previous: "السابق",
  NewCategory: "فئة جديد ",
  NewCountry: "دولة جديدة",
  UpdateCountry: "تعديل الدولة ",
  cancellations: "الإلغاءات",
  activeAndInActiveCustomersReport: "العملاء النشطين وغير النشطين",
  activeAndInActiveDeliveriesReport: "عمليات التوصيل النشطة وغير النشطة",
  CompaniesOrdersActivitesReport: " طلب الشركات النشطة",
  Satisfactions: "رضا العملاء",
  Payments: "المدفوعات",
  CompaniesOrdersActivites: " طلب الشركات النشطة",
  OrderSLA: "مستوى أداء الطلبات",
  Next: "التالي",
  Amount: "القيمة",
  User: "المستخدم",
  Rate: "التقييم",
  UserType: "نوع المستخدم",
  orderDelivery: "تسليم الطلب",
  Actions: "افعال",
  datePlaced: "تاريخ الطلب ",
  dateMessage: "تاريخ الرساله ",
  Status: "حالة الطلب",
  Statuss: "الحالة",
  Driver: "السائق",
  OrderID: "رقم الطلب",
  Customers: "العملاء",
  name: "الاسم",
  active: "نشط",
  Advertisements: "الإعلانات",
  Advertisement: "الإعلان",
  advertisementimage: "صورة الإعلان",
  NewAdvertisement: "أضف إعلان",
  UpdateAdvertisement: "تعديل إعلان",
  description: "الوصف",
  link: "الرابط",
  Upload: "تحميل",
  cancelationReport: "تقارير الإلغاء ",
  DeliveryCaptainlist: "قائمة سائقي التوصيل",
  ThisFieldisrequired: "هذا الحقل مطلوب!",
  orderRate: "تقييم الطلب",
  orderRateToDriver: "تقييم العميل إلى السائق",
  orderRateToCustomer: "تقييم السائق إلى العميل",
  ReceiptImage: " صورة الإيصال",
  LE: "درهم",
  SAR: "درهم",
  from: "من",
  to: "إلى",
  CancelationReason: "سبب الإلغاء",
  TotalPurchases: "اجمالي المشتريات",
  deliveryInformation: "معلومات التوصيل",
  delivery: "التوصيل",
  orderInfo: "بيانات الطلب",
  deliveryType: "نوع التوصيل",
  products: "المنتجات",
  deliveryTime: "وقت التوصيل",
  deliveryNotes: "ملاحظات التوصيل",
  deliveryCost: "تكلفة التسليم",
  VAT: "رسوم الخدمه",
  subTotal: "المجموع الفرعي",
  total: "المجموع",
  trackDelivery: "تتبع الطلب",
  proceedToPayment: "متابعة الدفع",
  packageSize: "حجم الطرد",
  categoryType: "نوع الفئة",
  doYouWantToDeleteThisAddress: "? هل ترغب في إلغاء هذا العنوان",
  locationInfo: "بيانات الموقع",
  deliveryCompany: "شركة التوصيل",
  paymentOwner: "مالك الدفع",
  edit: "تعديل",
  confirm: "تأكيد",
  deliveryService: "خدمة التوصيل",
  DriverInformation: "معلومات السائق",
  CustomerInformation: "معلومات العميل",
  Newsletter: "النشرة الإخبارية",
  SubscribedDate: "تاريخ الاشتراك",
  id: "رمز تعريف",
  UpdateBlog: "تحديث المدونة",
  RateThisDelivery: "قييم خدمة التوصيل",
  Ratedelivery: "تقييم الطلبية",
  Open: "يفتح",
  InProgress: "في تَقَدم",
  Closed: "مغلق",
  Individual: "فردي",
  Chauffeur: "شوفير",
  Corporate: "شركة كبرى",
  ServiceType: "نوع الخدمة",
  Standard: "معيار",
  Express: "يعبر",
  Scheduled: "المقرر",
  UserName: "أسم المستخدم",
  UserMobile: "رقم التواصل",
  UserEmail: "البريد الالكتروني",
  OrderID: "رقم الطلب",
  OrderDate: "تاريخ الطلب",
  OrderStatus: "حالة الطلب",
  ReceiverInformation: "معلومات المتلقي",
  GeneratePaymentLink: "إنشاء رابط الدفع",
  UpdateOrder: "تحديث الطلب",
  Coupon: "قسيمة",
  sendCoupon: "إرسال قسيمة",
  generateCoupon: "إنشاء قسيمة",
  CreationDate: "تاريخ الإنشاء",
  CouponCodeType: "نوع القسيمة",
  NewCoupon: "قسيمة جديدة",
  Code: "شفرة",
  ExpirationDate: "تاريخ انتهاء الصلاحية",
  MaxUsesPerUser: "أقصى استخدام لكل مستخدم",
  AllCities: "جميع المدن",
  AllCompanies: "جميع الشركات",
  RedeemAmountOrPercent: "استرداد المبلغ أو النسبة المئوية",
  MinOrderAmount: "الحد الأدنى لمبلغ الطلب",
  FromCity: "من المدينة",
  ToCity: "الى المدينة",
  NotificationTemplate: "قالب الإخطار",
  CreateNotificationTemplate: "إنشاء قالب الإخطار",
  EditNotificationTemplate: "تحرير قالب الإخطار",
  EmailTemplate: "قالب البريد الإلكتروني",
  CreateEmailTemplate: "إنشاء قالب البريد الإلكتروني",
  EditEmailTemplate: "تحرير قالب البريد الإلكتروني",
  MessageEn: "الرسالة (بالإنكليزية)",
  MessageAr: "الرسالة (باللغة العربية)",
  SelectNotificationType: "حدد نوع الإخطار",
  EditNotificationTemplate: "تحرير قالب الإخطار",
  EmailType: "نوع البريد الإلكتروني",
  Subject: "موضوع",
  SaveTemplate: "حفظ القالب",
  SendPaymentLink: "إرسال رابط الدفع",
  Discount: "تخفيض",
  ReassignCompany: "إعادة تعيين الشركة",
  PaymentLogs: "سجلات الدفع",
  BlogCategory: "فئة المدونة",
  deliveryDate: "تاريخ التسليم او الوصول",
  CreatorName: "اسم المنشئ",
  CreatorContactNo: "رقم الاتصال بالمنشئ",
  CancelledBy: "تم الإلغاء بواسطة (المستخدم، الشركة، السائق)",
  OrderAmount: "مبلغ الطلب",
  CompletedPaidOrNot: "أكملت المدفوعة أم لا",
  ReasonofCancelation: "سبب الإلغاء",
  ChauffeurRemarks: "ملاحظات السائق",
  PaymentInfo: "معلومات الدفع",
  PaymentType: "نوع الدفع",
  Payer: "دافع",
  ConfirmSendNotificationTemplate:
    "هل تريد إرسال هذا الإشعار إلى جميع المستخدمين؟",
  Fragile: "قابل للكسر",
  Reefer: "المبردة",
};
